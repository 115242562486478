@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair:ital,opsz,wght@0,5..1200,300;0,5..1200,400;0,5..1200,500;0,5..1200,600;0,5..1200,700;0,5..1200,800;0,5..1200,900;1,5..1200,300;1,5..1200,400;1,5..1200,500;1,5..1200,600;1,5..1200,700;1,5..1200,800;1,5..1200,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Qwitcher+Grypen:wght@400;700&display=swap');
@import "react-slideshow-image/dist/styles.css";

$primary-color: #cba554;
$primary-light-color: #F7F2ED;
$secondary-color: #b6bcad;
$secondary-color-2: #818878;
$font-color: #818285;
$gray-bg-color: rgba(217, 217, 217, 0.22);

@mixin breakpoint($class) {
  @if $class==xs {
      @media (max-width: 540px) {
          @content;
      }
  }
  @else if $class==sm {
      @media (max-width: 768px) {
          @content;
      }
  }
  @else if $class==md {
      @media (max-width: 992px) {
          @content;
      }
  }
  @else if $class==lg {
      @media (max-width: 1200px) {
          @content;
      }
  }
  @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

* {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

p, h1,h2, h3, h4, h5, h6 {
  color: $font-color;
}

.playfair-font {
  font-family: 'Playfair', serif;
}

#canvas-container {
  width: 100%;
  height: 100vh !important;
}

.preview-card {
  width: 400px;
  height: 400px;
  padding: 0;
}

.preview-card .card-body {
  padding: 0;
}

.option-img {
  width: 100px;
}

.fabric-picker-card {
  padding: 0;
}
.fabric-picker-card .fabric-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 0;
  background-color: aliceblue;
}

.fabric-gallery .fabric-card {
  padding: 0;
  border: 0;
  cursor: pointer;
  width: 200px;
  height: 200px;
}

.fabric-gallery .fabric-card .card-body {
  padding: 0;
  position: relative;
}
.fabric-gallery .fabric-card .fabric-img {
  width: 100%;
  height: 100%;
}
.fabric-gallery .fabric-card .overlay-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 0.5rem;
}

.fabric-picker-card {
  padding: 0;
  border: 0;
  background-color: transparent;
}

.fabric-picker-card .card-body {
  padding: 0;
  background-color: transparent;
}

.quantity-selector .input-box .item {
  width: 30px;
  text-align: center;
  border-radius: 0;
}

.quantity-selector .input-box {
  width: fit-content;
  background-color: antiquewhite;
}

.vh-100 {
  height: 100vh !important;
}

.colors .color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
/**
* Backgrounds
*/
.bg-primary {
  background-color: $primary-color !important;
}
.bg-secondary {
  background-color: $secondary-color !important;
}
.bg-primary-light {
  background-color: $primary-light-color;
}
/**
* Text
*/
.text-primary {
  color: $primary-color !important;
}
.text-secondary {
  color: $secondary-color !important;
}
/**
* Buttons
**/
.btn {
  &.btn-fill-type-batting {
    border: 0;
    &:active {
      border: 0;
    }
  }
}
.btn-primary {
  background-color: $primary-color;
  color: #fff;
  border-color: $primary-color;
  &:hover,
  &:active,
  &:focus {
    background-color: $primary-color;
    color: #fff;
    border-color: $primary-color;
    opacity: 0.7;
  }
}
.bg-transparent-gray {
  background-color: #00000080;
  color: #fff;
  border: none;
  border-radius: 0;
  &:hover, &:focus, &:active {
    background-color: #00000040;
  }
  .text {
    font-size: 20px;
    font-weight: 400;
  }
}

.card {
  &.cushion-type-card {
    border-color: rgb(226, 226, 226);
    cursor: pointer;
    overflow: hidden;
    &.active,
    &:hover {
      border: 1px solid $primary-color;
    }
  }
}

.btn-uno {
  background-color: grey;
  color: #fff;
  opacity: 0.4;
  border-radius: 0;
  &.active,
  &:hover {
    background-color: $primary-color;
    opacity: 1;
    color: #fff;
    border: 0;
  }
}

.three-d-renderer {
  height: 100vh;
  background-color: white;
  overflow: hidden;
  .header {
    padding: 2rem auto;
    height: 18%;
    // background-color: red;
    overflow: hidden;
    position: relative;
    .img-logo {
      position: absolute;
      height: 50%;
      top: 0;
      &.left {
        left: -40px;
      }
      &.right {
        right: -40px;
      }
      .img {
        width: 80px;
      }
    }
    .steps-container {
      background: #818878;
      color: #fff;
      padding: 1rem 0.5rem;
      .step {
        margin: 0 3rem;
        .number {
          background-color: #fff;
          color: $secondary-color-2;
          width: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
        }
        &.active {
          .number {
            background-color: $primary-color;
            color: #fff;
          }
        }
      }
    }
    .product-info {
      background: $secondary-color;
      color: #fff;
      padding: 0.2rem 2rem;
    }
  }
  .preview-container {
    width: 500px;
    height: 500px;
    padding: 1rem;
    .preview-content {
      background-color: #3b3b3b;
      overflow: hidden;
      height: 100%;
      width: 100%;
      border-radius: 15px;
      .preview-header {
        background-color: #818878;
        padding: 0.5rem 1rem;
      }
    }
  }
  .configurator-container {
    width: 100%;
    height: 80vh;
    overflow-y: scroll;
    padding: 0.5rem 0;
    // background-color: rgb(250, 250, 250);
    /* padding: 2rem; */
    .configurator-container {
      padding: 1rem 2rem;
    }
  }
}

.modal-btn {
  color: $primary-color;
  .icon {
    color: $secondary-color;
  }
  &:active,
  &:focus {
    border: 0;
  }
}
table {
  &.fill-type {
    table-layout: fixed;
    td {
      width: 33%;
    }
  }
}
.review-order {
  .card-fabric {
    border-color: rgba(155, 155, 155, 0.5);
    overflow: hidden;
    width: 190px;
    .card-body {
      padding: 0;
      .img {
        height: 100px;
      }
    }
  }
  .features-list {
    list-style: none;
    padding: 0;
    margin: 0;
    p {
      font-size: 1rem;
    }
    .label {
      font-weight: 500;
    }
  }
}

.fabric-box {
  border: 1px solid rgb(0, 0, 0);
  padding: 0.1rem;
  border-style: dotted;
  border-radius: 0.3rem;
  img {
    height: 150px;
    &.h-half {
      height: 42px;
    }
  }
}

.filter_colors {
  margin: 1rem 0;
  .color-box {
    width: 40px;
    height: 40px;
    border: 1px solid rgb(46, 46, 46);
    cursor: pointer;
    &.active {
      border: 4px solid $primary-color;
    }
    .img-color {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

/**
* Utility
**/
.shape-card {
  width: 150px;
}

.active-fabric-image {
  width: 500px;
}

/* Links */
a, .link {
  color: $primary-color;
  text-decoration: none;
  &:hover {
    color: $primary-color;
  }
}

/* Main Header */
.main-header {
  .header-top {
    button {
      background-color: transparent;
      border: 0;
      &:hover, &:focus {
        background-color: transparent;
      }
      &::after {
        display: none;
      }
    }
  }
  .header-mid {
    background-color: $secondary-color-2;
    p {
      color: white;
    }
  }
  .header-bottom {
    .cart-btn {
      background-color: transparent;
      border: 0;
      position: relative;
      .badge {
        position: absolute;
        top: -3px;
        right: -2px;
        padding: 3px 4px;
        min-width: 15px;
        min-height: 15px;
        font-weight: 500;
        font-size: 10px;
      }
    }
  }
}

/* Main Footer */
.main-footer {
  .footer-top {
    background-color: $primary-light-color;
    border-top: 2px solid $secondary-color-2;
    border-bottom: 2px solid $secondary-color-2;
    form {
      border-radius: 0;
      border-bottom: 2px solid $font-color;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      input {
        background-color: transparent;
        border: 0;
        font-size: 20px;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .footer-mid {
    .contact-item {
      svg {
        color: $font-color;
      }
      p {
        font-size: 18px;
      }
      &:hover {
        svg, p {
          color: $primary-color !important;
        }
      }
    }
    .nav {
      .nav-link {
        color: $font-color;
        font-size: 18px;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  .footer-bottom {
    .row {
      border-top: 1px solid $font-color;
    }
  }
}

/* Home */

/* Herobox */
.herobox {
  h2 {
    color: $primary-color;
    font-size: 34px;
     @include breakpoint(md) {
      font-size: 34px;
     }
  }
  img {
    width: 100%;
    height: 100%;
  }
}

/* About */
.about {
  img {
    width: 70%;
    border-radius: 10px;
    @include breakpoint(md) {
      width: 100%;
    }
  }
}

/* Cushion Designer */
.cushion-designer {
  .big-heading {
    font-size: 60px;
    @include breakpoint(sm) {
      font-size: 40px;
    }
  }
}

/* Pillow Designer */
.pillow-designer {
  .category-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 200px;
    .overlay {
      background-color: #00000050;
    }
  }
}

/* Products */
.products {
  background-color: $primary-light-color;
}

/* Product Box */
.product-box {
  cursor: pointer;
  .img-wrapper {
    position: relative;
    min-height: 220px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    img {
      width: 100%;
      height: 100%;
      min-height: 220px;
      border: 1px solid lightgray;
    }
    .actions {
      position: absolute;
      bottom: 15px;
      right: 10px;
    }
  }
  .brand {
    font-size: 14px;
  }
}

/* Pagination */
.pagination {
  display: flex;
  flex-wrap: wrap;
  li {
    margin: 5px;
    a {
      border: 1px solid $secondary-color;
      border-radius: 3px;
      padding: 4px 8px;
      cursor: pointer;
    }
    &:hover {
      a {
        color: white !important;
        background-color: $primary-color;
      }
    }
  }
  .active {
    a {
      color: white;
      background-color: $primary-color;
    }
  }
  .disabled {
    a {
      background-color: lightgray;
      color: $font-color;
      cursor: not-allowed;
    }
    &:hover {
      a {
        background-color: lightgray;
        color: $font-color !important;
      }
    }
  }
}

/* Testimonials */
.testimonials {
  .testimonial-card {
    box-shadow: 5px 10px 0 #f8f8f8;
    svg {
      color: rgb(255, 208, 0);
    }
    img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      object-position: center;
      margin-top: -70px;
      border: 10px solid white;
    }
  }
}

/* CTA */
.cta {
  h3 {
    color: white;
  }
}

/* Gallery */
.gallery {
  img {
    width: 100%;
  }
}

/* Color Strap */
.color-strap {
  .multi {
    background: linear-gradient(to right, red 0% 14%, orange 14% 28%, yellow 28% 42%, green 42% 56%, blue 56% 70%, indigo 70% 84%, violet 84% 98%);
  }
}

/* Shop */
.shop {
  .filters {
    background-color: $gray-bg-color;
  }
  .multi-range-slider {
    .bar-inner, .bar-right, .bar-left {
      box-shadow: none;
      border: none;
    }
    .thumb {
      &::before {
        box-shadow: none;
        border: none;
      }
      .caption {
        * {
          background-color: $primary-color;
        }
      }
    }
  }
}

/* Single Product */
.single-product {
  .topbar {
    border-bottom: 1px solid $secondary-color;
    h2 {
      color: $secondary-color-2;
    }
    img {
      width: 13%;
      @include breakpoint(md) {
        width: 20%;
      }
      @include breakpoint(xs) {
        width: 40%;
      }
    }
  }
  .product-img {
    width: 100%;
    min-width: 100%;
    min-height: 400px;
    border: 1px solid lightgray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .magnifier-icon {
    position: absolute;
    bottom: 15px;
    right: 10px;
    border-radius: 50px;
    padding: 0 7px 3px 7px;
    background-color: white;
  }
  .price-box {
    width: fit-content;
  }
  .quantity-box {
    width: fit-content;
    button {
      height: auto;
    }
  }
  .quantity-instruction {
    font-size: 15px;
  }
}

/* Cart */
.cart {
  .topbar {
    background-color: $secondary-color-2;
    h4 {
      color: white;
    }
  }
  .cart-items-header {
    border-bottom: 2px solid $primary-color;
  }
  .cart-item {
    border-bottom: 1px solid lightgray;
    @include breakpoint(xs) {
      border: 1px solid lightgray;
    }
    img {
      width: 70%;
      @include breakpoint(xs) {
        width: 100%;
      }
    }
    input {
      width: 25%;
      @include breakpoint(md) {
        width: 40%;
      }
      @include breakpoint(xs) {
        width: 30%;
      }
    }
  }
  .checkout-btn {
    &:hover, &:focus, &:active {
      opacity: 1;
    }
  }
}

/* Checkout */
.checkout {
  .topbar {
    background-color: $secondary-color-2;
    h4 {
      color: white;
    }
  }
  input {
    font-weight: 300;
    &:focus {
      box-shadow: none;
      border-color: gray;
    }
  }
  .order-summary {
    background-color: $gray-bg-color;
    img {
      width: 20%;
    }
  }
  .payment-details {
    background-color: $gray-bg-color;
  }
  .place-order-btn {
    &:hover, &:focus, &:active {
      opacity: 1;
    }
  }
}
.checkout-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 9999;
  opacity: 0.8;
}
.checkout-tabs {
  .nav-item {
    button {
      color: $primary-color;
    }
  }
}

/* Dashboard */
.dashboard {
  .topbar {
    background-color: $secondary-color-2;
    h4 {
      color: white;
    }
  }
  .rdt_Table {
    .rdt_TableHeadRow {
      * {
        font-weight: 500;
        color: $font-color;
      }
    }
    img {
      width: 10%;
    }
  }
}

/* Login */
.login {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .overlay {
    background-color: black;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 90;
  }
  .content {
    * {
      z-index: 100;
    }
    h2 {
      font-size: 60px;
      color: white;
      @include breakpoint(xs) {
        font-size: 40px;
      }
    }
    p {
      font-size: 20px;
      color: white;
      padding-right: 150px;
      @include breakpoint(md) {
        padding-right: 0;
      }
    }
    button {
      &:hover, &:focus, &:active {
        opacity: 1;
      }
    }
  }
}

/* Quick View Modal */
.quick-view-modal {
  .product-img {
    width: 100%;
    min-width: 100%;
    min-height: 300px;
    border: 1px solid lightgray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}